import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { ThreeElements } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { useAtom } from "jotai";
import { sceneAtom } from "./atoms";
import { useSpring, animated } from "@react-spring/three";

export function Camera(props: ThreeElements["perspectiveCamera"]) {
  const ref = useRef<THREE.PerspectiveCamera>(null!);
  const lookAtPoint = new THREE.Vector3(0, 0, 0);
  const [scene] = useAtom(sceneAtom);

  useEffect(() => {
    // 👇️ get global mouse coordinates
    const handleWindowMouseMove = (event) => {
      const coords = {
        x: (event.screenX - window.innerWidth / 2) / window.innerWidth,
        y: (event.screenY - window.innerHeight / 2) / window.innerHeight,
      };

      // const movement_multiplier = scene === "intro" ? 0.1 : 1;
      const movement_multiplier = 1;

      ref.current.position.x = coords.x * movement_multiplier;
      ref.current.position.y = Math.max(
        (coords.y + 0.1) * -movement_multiplier,
        -1.5
      );
      ref.current.lookAt(lookAtPoint);
      ref.current.updateProjectionMatrix();
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [scene]);

  return <PerspectiveCamera makeDefault position={[0, 0, 10]} ref={ref} />;
}
