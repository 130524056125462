import React from "react";
import projects from "../content/projects";
import { AboutContent } from "../content/about-clean";
import ReactPlayer from "react-player";
import { ContactContent } from "../content/contact";
import { PeopleEvanneContent } from "../content/people-evanne";
import { PeopleSikkoContent } from "../content/people-sikko";
import { descriptionProcessor } from "../utils";

export const Mobile = () => {
  return (
    <div className="mobile-container">
      {/* <video autoPlay loop muted className="mobile-video">
        <source src="/videos/mobile.mp4" type="video/mp4" />
      </video> */}
      <div className="mobile-header"></div>
      <div className="view-on-desktop">
        <h2>Ga naar deze website op je computer, dat is leuker!</h2>
      </div>
      <section className="mobile-about">
        <AboutContent />
      </section>
      <section>
        <h1>Projecten</h1>
        {projects.map((project, i) => (
          <div className="mobile-project">
            <div className="player-wrapper">
              <ReactPlayer url={project.fullVideo} width="100%" height="100%" />
            </div>
            <h2>{project.title}</h2>
            <p className="muted">{descriptionProcessor(project.description)}</p>
          </div>
        ))}
      </section>
      <section className="mobile-people">
        <h1>De Saus</h1>
        <div>
          <PeopleEvanneContent />
          <PeopleSikkoContent />
        </div>
      </section>
      <section>
        <ContactContent />
      </section>
      <footer>
        <p className="copyright">
          &copy; {new Date().getFullYear()} Studio Saus
        </p>
      </footer>
    </div>
  );
};
