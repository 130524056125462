import React from "react";

export const ContactContent = () => {
  return (
    <>
      <h1>Samenwerken?</h1>
      <p>
        Bel ons op <a href="tel:+31622641499">+31 6 22641499</a>
      </p>
      <p>
      Of mail naar: <a href="mailto:hoi@studiosaus.nl">hoi@studiosaus.nl</a>
      </p>
    </>
  );
};
