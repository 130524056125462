import * as THREE from "three";
import React, { Suspense } from "react";
import { useGLTF, useTexture, useVideoTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useAtom } from "jotai";
import { Video, videoAtom } from "./atoms";
import video_urls from "./content/video_urls.json";

type GLTFResult = GLTF & {
  nodes: {
    Circle001_larger_baseplate_0: THREE.Mesh;
    Glas: THREE.Mesh;
    TV: THREE.Mesh;
    Canvas: THREE.Mesh;
    Cube: THREE.Mesh;
  };
  materials: {
    larger_baseplate: THREE.MeshStandardMaterial;
    ["Shell.004"]: THREE.MeshPhysicalMaterial;
    ["Shell.002"]: THREE.MeshStandardMaterial;
    ["Shell.003"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
  };
};

const FallbackMaterial = ({ noiseTexture }) => {
  return (
    <Suspense
      fallback={
        <meshStandardMaterial
          toneMapped={false}
          metalness={1}
          color="#575757"
        />
      }
    >
      <meshBasicMaterial map={noiseTexture} toneMapped={false} />
    </Suspense>
  );
};

const VideoMaterial = ({ url, props }) => {
  const texture = useVideoTexture(url, {
    start: true,
    loop: true,
    muted: true,
    playsInline: true,
    ...props,
  });

  return <meshBasicMaterial map={texture} toneMapped={false} />;
};

export function Screen(props: JSX.IntrinsicElements["group"]) {
  const [currentVideo, setCurrentVideo] = React.useState<Video | null>(null);

  const [video] = useAtom(videoAtom);

  React.useEffect(() => {
    if (video !== null && video.src !== null) {
      if (video.instant) {
        setCurrentVideo(video);
      } else {
        setCurrentVideo({
          src: video_urls.static,
          instant: true,
          props: {
            start: true,
            loop: true,
            muted: true,
            playsInline: true,
          },
        });
        setTimeout(() => {
          setCurrentVideo(video);
        }, 150);
      }
    }
  }, [video]);

  const noiseTexture = useVideoTexture(video_urls.static, {
    start: true,
    loop: true,
    muted: true,
    playsInline: true,
  });

  const { nodes, materials } = useGLTF(
    "/models/tv.glb"
  ) as unknown as GLTFResult;

  return (
    <mesh
      geometry={nodes.Canvas.geometry}
      material={materials["Shell.003"]}
      position={[0.45, -0.13, 0]}
      rotation={[-Math.PI / 2, 0, 0]}
    >
      <Suspense fallback={<FallbackMaterial noiseTexture={noiseTexture} />}>
        {currentVideo !== null && currentVideo.src !== null ? (
          <VideoMaterial url={currentVideo.src} props={currentVideo.props} />
        ) : (
          <meshStandardMaterial
            color="#202020"
            metalness={0.8}
            roughness={0.1}
            emissive="#3c3c3c"
            emissiveIntensity={1}
          />
        )}
      </Suspense>
    </mesh>
  );
}
