import React from "react";

export const AboutContent = () => {
  return (
    <>
      <h1>De Studio</h1>
      <p>
        <span className="muted">
          Zie je wel eens een gelikte video waarvan je denkt: pfffffff, had ik
          ‘m maar bedacht!
        </span>
      </p>
      <p>
        <span className="muted">
          Zo’n verrukkelijke video waarvan het verhaal je
        </span>{" "}
        <strong>tot in het diepste raakt.</strong>{" "}
        <span className="muted">
          Visueel spatten de video's van je scherm en dat ook nog eens op een
          heerlijk ritme. Een video waarvan je even moet bijkomen, om vervolgens
          op repeat te klikken. Dat is waar wij voor gaan.
        </span>
      </p>
      <p>
        <span className="muted">
          We kruipen in het hoofd van je kijker en zoeken naar manieren om te
          prikkelen. We creëren de video die je voor ogen had, maar niet
          uitgevoerd kreeg.
        </span>{" "}
        <strong>Welkom bij Studio Saus.</strong>
      </p>
    </>
  );
};
