import { animated, useTransition } from "@react-spring/web";
import { useAtom } from "jotai";
import React from "react";
import { hitsAtom } from "../atoms";

export const StatsSection = ({ active }) => {
  const [allTimeHits, setAllTimeHits] = useAtom(hitsAtom);

  const trans = useTransition(active, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {trans(
        (style, item) =>
          item && (
            <animated.section
              className="scene-section info-section"
              style={style}
            >
              <p className="copyright">
                &copy; {new Date().getFullYear()} Studio Saus
              </p>
              <p>
                Je hebt sinds {localStorage.getItem("firstHit") || "vandaag"} de
                televisie al {allTimeHits} keer geslagen.
              </p>
            </animated.section>
          )
      )}
    </>
  );
};
