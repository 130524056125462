import { useAtom } from "jotai";
import React from "react";
import { videoModalAtom } from "./atoms";
import ReactPlayer from "react-player/lazy";
import { animated, useTransition } from "@react-spring/web";
import { RiCloseLine } from "react-icons/ri";

export const VideoModal = () => {
  const [videoModal, setVideoModal] = useAtom(videoModalAtom);

  const trans = useTransition(videoModal !== null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {trans(
        (style, item) =>
          item && (
            <animated.div style={style} className="modal-fader">
              <div className="modal">
                <div className="modal-inner">
                  <ReactPlayer
                    url={videoModal}
                    controls
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <div
                className="modal-backdrop"
                onClick={() => setVideoModal(null)}
              />
              <div className="modal-close" onClick={() => setVideoModal(null)}>
                <RiCloseLine />
              </div>
            </animated.div>
          )
      )}
    </>
  );
};
