import { animated, useSpring, useTransition } from "@react-spring/web";
import { useAtom } from "jotai";
import React from "react";
import { videoAtom } from "../atoms";
import { AboutContent } from "../content/about";
import projects from "../content/projects.json";

export const AboutSection = ({ active }) => {
  const trans = useTransition(active, {
    from: { opacity: 0, transform: "translate3d(0, -50%, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0%, 0)" },
    leave: { opacity: 0, transform: "translate3d(0, -50%, 0)" },
  });

  return (
    <>
      {trans(
        (style, item) =>
          item && (
            <animated.section
              className="scene-section about-section"
              style={style}
            >
              <AboutContent />
            </animated.section>
          )
      )}
    </>
  );
};
