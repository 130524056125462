import React from "react";
import ReactMarkdown from "react-markdown";

export const descriptionProcessor = (string: string) => {
  return (
    <ReactMarkdown
      className="bold-white"
      components={{
        p: ({ node, ...props }) => {
          return <p {...props} />;
        },
      }}
    >
      {string}
    </ReactMarkdown>
  );
};
