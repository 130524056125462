import React from "react";

export const PeopleEvanneContent = () => {
  return (
    <>
      <h2>Evanne</h2>
      <p>
        <span className="muted">
          Ik heb niet alleen gevoel voor video, maar ook voor
        </span>{" "}
        <strong>muziek</strong>
        <span className="muted">
          . Die twee werelden komen mooi samen bij Studio Saus.
        </span>
      </p>
      <p>
        <span className="muted">
          Met mijn positieve mindset en doorzettingsvermogen, zorg ik ervoor dat
          alleen de beste eindproducten de wereld ingaan. Daarin ben ik een
          tikkeltje perfectionistisch. Zo hou ik Sikko ook een beetje in toom.
        </span>
        <strong>Wat gaan we voor je maken?</strong>
      </p>
    </>
  );
};
