import * as THREE from "three";
import React, { useRef } from "react";
import { PivotControls, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Object_2: THREE.Mesh;
  };
  materials: {
    material_0: THREE.MeshStandardMaterial;
  };
};

export function Hand(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/models/hand.glb"
  ) as unknown as GLTFResult;

  const transform = new THREE.Matrix4();
  // Transform matrix:
  // Array (16) = $1
  // 0 -0.08344772177910009
  // 1 0.43420783887526726
  // 2 -0.896939256800119
  // 3 0
  // 4 0.2647586657703099
  // 5 -0.8580691431145979
  // 6 -0.44002294773580347
  // 7 0
  // 8 -0.9606973127002093
  // 9 -0.2741913534264892
  // 10 -0.04335637296576742
  // 11 0
  // 12 1.4810403673221098
  // 13 -0.0075354340591591296
  // 14 0.5594529630725571
  // 15 1.0000000000000002

  transform.set(
    -0.08344772177910009,
    0.2647586657703099,
    -0.9606973127002093,
    1.4810403673221098,
    0.43420783887526726,
    -0.8580691431145979,
    -0.2741913534264892,
    -0.0075354340591591296,
    -0.896939256800119,
    -0.44002294773580347,
    -0.04335637296576742,
    0.5594529630725571,
    0,
    0,
    0,
    1.0000000000000002
  );

  return (
    <group {...props} dispose={null} scale={0.05} position={[0.2, 0.1, 0]}>
      <group matrix={transform} matrixAutoUpdate={false}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_2.geometry}
          material={materials.material_0}
        />
      </group>
    </group>
  );
}

if (window.innerWidth >= 1024) {
  useGLTF.preload("/models/hand.glb");
}
