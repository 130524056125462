import React from "react";

export const PeopleSikkoContent = () => {
  return (
    <>
      <h2>Sikko</h2>
      <p>
        <strong>Creatief zijn is het leukste wat er is.</strong>{" "}
        <span className="muted">
          De kunst is om vanuit niets, iets unieks te maken. En telkens anders.
          Ik word gelukkig van mooie dingen maken.
        </span>
      </p>
      <p>
        <span className="muted">Ook </span>
        <strong>jouw verhaal</strong>
        <span className="muted">
          {" "}
          kan ik op ludieke wijze vertellen. Feesten doe ik niet alleen in het
          weekend, maar ook op de werk- vloer met Evanne. Kom maar op met je
          vraagstuk.
        </span>
      </p>
    </>
  );
};
