import { animated, useTransition } from "@react-spring/web";
import { useAtom } from "jotai";
import React from "react";
import { hitsAtom } from "../atoms";

export const IntroSection = ({ active }) => {
  const trans = useTransition(active, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {trans(
        (style, item) =>
          item && (
            <animated.section
              className="scene-section intro-section"
              style={style}
            >
              <h2>
                Klik op de televisie om
                <br />
                deze aan te zetten.
              </h2>
            </animated.section>
          )
      )}
    </>
  );
};
