import { animated, useSpring, useTransition } from "@react-spring/web";
import { useAtom } from "jotai";
import React from "react";
import { videoAtom, videoModalAtom, sceneAtom } from "../atoms";
import projects from "../content/projects";
import {
  RiArrowLeftSLine,
  RiPlayFill,
  RiArrowRightSLine,
} from "react-icons/ri";
import { descriptionProcessor } from "../utils";

export const ProjectsSection = ({ active }) => {
  const [video, setVideo] = useAtom(videoAtom);
  const [scene, setScene] = useAtom(sceneAtom);
  const [videoModal, setVideoModal] = useAtom(videoModalAtom);

  const [activeProjectIndex, setActiveProjectIndex] = React.useState<
    number | null
  >(null);

  const trans = useTransition(active, {
    from: { opacity: 0, transform: "translate3d(100%, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(100%, 0, 0)" },
  });

  const onProjectHover = (project) => {
    setVideo({
      src: project.video,
      instant: false,
      props: {},
    });
  };

  React.useEffect(() => {
    if (scene !== "projects") {
      setActiveProjectIndex(null);
    }
  }, [scene]);

  return (
    <>
      {trans(
        (style, item) =>
          item && (
            <animated.section
              className="scene-section projects-section"
              style={style}
            >
              {activeProjectIndex === null && (
                <ul className="projects-list">
                  {projects.map((project, i) => (
                    <li
                      key={project.title}
                      onMouseEnter={() => onProjectHover(project)}
                    >
                      <button
                        className="project-button"
                        onClick={() => setActiveProjectIndex(i)}
                      >
                        <RiArrowRightSLine className="project-arrow" />
                        <span>{project.title}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              {activeProjectIndex !== null && (
                <div className="single-project">
                  <div>
                    <button
                      onClick={() => setActiveProjectIndex(null)}
                      className="project-back-button"
                    >
                      <RiArrowLeftSLine />
                      Alle projecten
                    </button>
                  </div>
                  <h2>{projects[activeProjectIndex].title}</h2>
                  <p className="muted">
                    {descriptionProcessor(projects[activeProjectIndex].description)}
                  </p>
                  <div>
                    <button
                      className="play-button"
                      onClick={() =>
                        setVideoModal(projects[activeProjectIndex].fullVideo)
                      }
                    >
                      <RiPlayFill />
                      <span>Bekijk video</span>
                    </button>
                  </div>
                </div>
              )}
            </animated.section>
          )
      )}
    </>
  );
};
