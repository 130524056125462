import { animated, useTransition } from "@react-spring/web";
import React from "react";
import { ContactContent } from "../content/contact";

export const ContactSection = ({ active }) => {
  const trans = useTransition(active, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {trans(
        (style, item) =>
          item && (
            <animated.section
              className="scene-section intro-section"
              style={style}
            >
              <ContactContent />
            </animated.section>
          )
      )}
    </>
  );
};
